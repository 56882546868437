import React from "react";

import Container from "@material-ui/core/Container";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    //padding: theme.spacing(0, 1),
    marginTop: theme.spacing(3),
    //...theme.mixins.toolbar,
    justifyContent: "flex-start"
  },
  contentContainer: {
    maxWidth: "960px",
    backgroundColor: "#fff",
    padding: 0
  }
}));

export default function Content({ children }) {
  const classes = useStyles();
  return (
    <Container className={classes.contentContainer}>
      <div className={classes.drawerHeader} />
      {children}
    </Container>
  );
}
