import React from "react";

import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";

// import ReactHelmet from "../components/helmet/ReactHelmet";
import Content from "../components/layout/content";

const useStyles = makeStyles(theme => ({
  container: {
    padding: theme.spacing(2, 3)
  },
  headingStyle: {
    fontWeight: "bold",
    marginTop: "10px",
    marginBottom: "10px"
  }
}));

export default function PrivacyPolicy() {
  const classes = useStyles();

  return (
    <Content>
      {/* <ReactHelmet title={"Privacy Policy"} /> */}
      {/*   */}
      <Container className={classes.container}>
        <Typography variant="h6" className={classes.headingStyle}>
          INTRODUCTION
        </Typography>
        <Typography variant="body1" paragraph color="textSecondary">
          Thank you for visiting tripplanner.travalour.com (the “Website”) and using the services we make available through our website and other mediums such as PlayStore and AppStore. Travalour Pte Ltd (“Company” or “We”), owner of TripPlanner and its services respects your privacy and is committed to protecting it through our compliance with this Privacy Policy. This Privacy Policy intends to inform you about how we treat information that is collected from you through TripPlanner app and its website. Please read this policy carefully to understand our policies and practices regarding your information and how we will treat it. If you do not agree with our policies and practices, your choice is not to use our Website or our services. By accessing or using this Website, you agree to this privacy policy. This policy may change from time to time (see Amendments, below). Your continued use of this Website or our Apps after we make changes is deemed to be acceptance of those changes, so please check the policy periodically for updates.
        </Typography>
        <Typography variant="h6" className={classes.headingStyle}>
          COLLECTED INFORMATIONS
        </Typography>
        <Typography variant="body1" paragraph color="textSecondary">
          TripPlanner is designed in such a way that user can use most of its functionalities without logging in to the app.
        </Typography>
        <Typography variant="body1" paragraph color="textSecondary">
          Without logging in, TripPlanner does not collect and store any information about its user. The application requires access to users location for it to function, but this information is neither collected nor stored or shared or tracked by TripPlanner servers. The travel plans that are created in the app are stored within the user’s phone and neither the plans nor the locations inside the plans are accessible to TripPlanner servers.
        </Typography>
        <Typography variant="body1" paragraph color="textSecondary">
          If you opt to log in to TripPlanner, TripPlanner does not ask for any identity information from the user. TripPlanner uses a quick login functionality without having the need for email address or phone numbers. However, by logging in and backing up your travel plans, all your travel plans are stored in TripPlanner databases.
        </Typography>
        <Typography variant="h6" className={classes.headingStyle}>
          THIRD-PARTY USE OF COOKIES AND OTHER TRACKING TECHNOLOGIES
        </Typography>
        <Typography variant="body1" paragraph color="textSecondary">
          Some features on the apps, like the maps are served by third-parties. These third parties may use cookies alone or in conjunction with web beacons or other tracking technologies to collect information about you when you use our apps. The information they collect may be associated with your personal information or they may collect information, including personal information, about your online activities over time and across different websites and apps, and other online services. They may use this information to provide you with interest-based (behavioural) advertising or other targeted content. We do not control these third parties’ tracking technologies or how they may be used. If you have any questions about an advertisement or other targeted content, you should contact the responsible provider directly.
        </Typography>
        <Typography variant="h6" className={classes.headingStyle}>
          AGE COMPLIANCE{" "}
        </Typography>
        <Typography variant="body1" paragraph color="textSecondary">
          TripPlanner, and its website and apps are intended for people of any age.
        </Typography>
        <Typography variant="h6" className={classes.headingStyle}>
          AMENDMENTS
        </Typography>
        <Typography variant="body1" paragraph color="textSecondary">
          We may amend this Privacy Policy from time to time. The date the privacy policy was last revised is identified at the bottom of the page. You are responsible for periodically visiting our Website and this privacy policy to check for any changes.
        </Typography>
        <Typography variant="h6" className={classes.headingStyle}>
          LAST MODIFIED: 21/01/2020
        </Typography>
      </Container>
    </Content>
  );
}
