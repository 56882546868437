import React, { Fragment } from "react";
import { Link as RouterLink } from "react-router-dom";

import Box from "@material-ui/core/Box";
import Divider from "@material-ui/core/Divider";
import Link from "@material-ui/core/Link";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import ListItemText from "@material-ui/core/ListItemText";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";

import Facebook from "@material-ui/icons/Facebook";
import Twitter from "@material-ui/icons/Twitter";
import Instagram from "@material-ui/icons/Instagram";

import {
  SOCIAL_FB_URL,
  SOCIAL_TWITTER_URL,
  SOCIAL_INSTA_URL,
  TRAVALOUR_BOOKING_URL,
  NEARBY_URL,
  TRAVALOUR_WEB_URL,
  ANDROID_PACKAGE_NAME_TRIP_PLANNER
} from "../../config";

import GooglePlayButton from "../../components/google-play/GooglePlayButton";
import AppStoreButton from "../app-store/AppStoreButton";

const useStyles = makeStyles(theme => ({

  menuItem: {
    color: theme.palette.primary.main,
    textDecoration: "none"
  },
  // socialIcon: { color: "white" },
  iconContainer: {
    display: "flex",
    alignItems: "center",
    marginBottom: 8,
    paddingLeft: 36,
  },
  linkText: {
    paddingTop: 4,
    paddingBottom: 4,
    paddingLeft: 4,
    fontSize: 12,
    // color: "white",
  },
}));

export default function Menu({ handleDrawerClose }) {
  const classes = useStyles();
  return (
    <Fragment>
      <MenuList>
        <MenuItem
          component={Link}
          href={TRAVALOUR_WEB_URL}
          target="blank"
          onClick={handleDrawerClose}
          className={classes.menuItem}
        >
          <ListItemText
            primary="Travalour"
            align="center"
            primaryTypographyProps={{ variant: "subtitle2" }}
            className={classes.menuItem}
          />
        </MenuItem>
        <MenuItem
          component={Link}
          href={NEARBY_URL}
          target="blank"
          onClick={handleDrawerClose}
          className={classes.menuItem}
        >
          <ListItemText
            primary="NearBy"
            align="center"
            primaryTypographyProps={{ variant: "subtitle2" }}
            className={classes.menuItem}
          />
        </MenuItem>
        <MenuItem
          component={Link}
          href={TRAVALOUR_BOOKING_URL}
          target="blank"
          onClick={handleDrawerClose}
          className={classes.menuItem}
        >
          <ListItemText
            primary="Flights"
            align="center"
            primaryTypographyProps={{ variant: "subtitle2" }}
          />
        </MenuItem>
        <MenuItem
          component={Link}
          href={TRAVALOUR_BOOKING_URL}
          target="blank"
          onClick={handleDrawerClose}
          className={classes.menuItem}
        >
          <ListItemText
            primary="Hotels"
            align="center"
            primaryTypographyProps={{ variant: "subtitle2" }}
          />
        </MenuItem>
        {/* <MenuItem
          component={RouterLink}
          to="/about-travalour"
          onClick={handleDrawerClose}
          className={classes.menuItem}
        >
          <ListItemText
            primary="About Us"
            align="center"
            primaryTypographyProps={{ variant: "subtitle2" }}
          />
        </MenuItem> */}
        <MenuItem
          component={RouterLink}
          to="/terms-of-use"
          onClick={handleDrawerClose}
          className={classes.menuItem}
        >
          <ListItemText
            primary="Terms Of Use"
            align="center"
            primaryTypographyProps={{ variant: "subtitle2" }}
          />
        </MenuItem>
        <MenuItem
          component={RouterLink}
          to="/privacy-policy"
          onClick={handleDrawerClose}
          className={classes.menuItem}
        >
          <ListItemText
            primary="Privacy Policy"
            align="center"
            primaryTypographyProps={{ variant: "subtitle2" }}
          />
        </MenuItem>
      </MenuList>
      <Divider />
      <Box display="flex" alignItems="center" flexDirection={"column"}>
        <GooglePlayButton packageName={ANDROID_PACKAGE_NAME_TRIP_PLANNER} />
        <AppStoreButton />
      </Box>
      <Divider />
      <Box display="flex" justifyContent="center" flexDirection={"column"}>
        <Link href={SOCIAL_TWITTER_URL} target="_blank">
          <Box className={classes.iconContainer}>
            <Twitter className={classes.socialIcon} />
            <Typography variant="body2" className={classes.linkText}>
              Twitter
            </Typography>
          </Box>
        </Link>

        <Link href={SOCIAL_FB_URL} target="_blank">
          <Box className={classes.iconContainer}>
            <Facebook className={classes.socialIcon} />
            <Typography variant="body2" className={classes.linkText}>
              Facebook
            </Typography>
          </Box>
        </Link>
        <Link href={SOCIAL_INSTA_URL} target="_blank">
          <Box className={classes.iconContainer}>
            <Instagram className={classes.socialIcon} />
            <Typography variant="body2" className={classes.linkText}>
              Instagram
            </Typography>
          </Box>
        </Link>
      </Box>
    </Fragment >
  );
}
