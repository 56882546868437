import React from "react";
import { Link as RouterLink } from "react-router-dom";

import Container from "@material-ui/core/Container";
import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    flexDirection: "column",
    minHeight: "100vh"
  },
  main: {
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  }
}));

export default function StickyFooter() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Container component="main" className={classes.main} maxWidth="sm">
        <Typography
          component="div"
          style={{
            fontSize: "125px",
            width: "auto",
            transform: "rotate(-60deg)"
          }}
          color="textSecondary"
        >
          <i className="tw tw-travalour-logo-circle"></i>
        </Typography>
        <Typography variant="h2" color="textSecondary" component="h1">
          OOPS!!!!
        </Typography>
        <Typography
          variant="h5"
          component="h2"
          color="textSecondary"
          gutterBottom
        >
          {"You are at the wrong place"}
          {""}
        </Typography>
        <Typography variant="body1" color="textSecondary">
          <Link color="inherit" component={RouterLink} to="/" underline="none">
            Let's go back home
          </Link>
        </Typography>
      </Container>
    </div>
  );
}
