import React, { useRef /*, useState*/ } from "react";

import Box from "@material-ui/core/Box";
import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";

import {
  // WEB_MEDIA_CDN_URL,
  ANDROID_PACKAGE_NAME_NEARBY,
  ANDROID_PACKAGE_NAME_TRAVALOUR,
  ANDROID_PACKAGE_NAME_TRIP_PLANNER,
  APPSTORE_URL
} from "../config";
import GooglePlayButton from "../components/google-play/GooglePlayButton";
import AppStoreButton from "../components/app-store/AppStoreButton";

const sectionTopPadding = "5%";
const sectionBottomPadding = "5%";
const gradientImage = "linear-gradient(to top, #F8F5FF, #fff)";
const gradient = "linear-gradient(to right, #fdfbfb 0%, #ebedee 100%)";

function pxToRem(value) {
  return `${value / 16}rem`;
}

const useStyles = makeStyles(theme => ({
  appDescr: {
    marginBottom: "20px",
    maxWidth: "50%",
    [theme.breakpoints.down("xs")]: {
      maxWidth: "90%"
    }
  },
  titileTextStyle: {
    fontFamily: "sans-serif",
    fontWeight: "900",
    fontSize: pxToRem(100),
    [theme.breakpoints.down("xs")]: {
      fontSize: pxToRem(50)
    }
  },

  headingTextStyle: {
    fontFamily: "sans-serif",
    fontWeight: "900",
    fontSize: pxToRem(60),
    [theme.breakpoints.down("xs")]: {
      fontSize: pxToRem(30)
    }
  },
  tagText: {
    color: "#6B52AE",
    fontWeight: "bold"
  },
  supportText: {
    color: "#4F4F4F",
    maxWidth: "60%",
    textAlign: "center",
    variant: "h6",
    [theme.breakpoints.down("xs")]: {
      maxWidth: "90%"
    }
  },
  cover: {
    width: "100%",
    display: "flex",
    minHeight: `calc(100vh)`, //
    flexDirection: "row", //
    justifyContent: "center",
    alignItems: "center",
    backgroundRepeat: "no-repeat",
    background: gradientImage,
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      minHeight: `calc(100vh)`,
      paddingTop: "5%",
      paddingBottom: "5%"
    }
  },
  coverLeftBox: {
    display: "flex",
    minHeight: `calc(100vh)`, //
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    maxWidth: "50%", //
    [theme.breakpoints.down("sm")]: {
      minHeight: `calc(10vh)`,
      maxWidth: "100%"
    }
  },
  coverMobile: {
    height: "calc(85vh)", //
    width: `calc(100vw * 0.5)`, //
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center center",
    backgroundSize: "contain",
    maxWidth: "80%", //
    alignSelf: "center",
    backgroundImage: `url("images/app-img.webp")`,
    [theme.breakpoints.down("sm")]: {
      height: "calc(100vh*0.5)",
      width: `calc(100vw * 0.6)`,
      maxWidth: "100%"
    },
    [theme.breakpoints.down("xs")]: {
      width: `calc(100vw * 0.8)`
    }
  },
  detailBox: {
    backgroundColor: "#fff",
    width: "100%",
    paddingTop: sectionTopPadding,
    paddingBottom: sectionBottomPadding,
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      minHeight: `0`,
      paddingTop: "10%",
      paddingBottom: "5%"
    }
  },
  imageContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column"
    }
  },
  detailImage: {
    width: "25%",
    height: `calc(100vh*0.7)`,
    marginTop: "10px",
    marginBottom: "50px",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center center",
    backgroundSize: "contain",
    [theme.breakpoints.down("sm")]: {
      height: `calc(100vh*0.7)`,
      width: "80%"
    }
  },
  appsBoxContainer: {
    paddingTop: "3%",
    marginBottom: "4%",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column-reverse"
    }
  },
  appsBoxTile: {
    flex: 1,
    [theme.breakpoints.down("sm")]: {
      margin: "30px 0 15px 0"
    }
  },
  mediaContainer: {
    paddingTop: "3%",
    marginBottom: "4%",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column-reverse"
    }
  },
  mediaTile: {
    flex: 1,
    [theme.breakpoints.down("sm")]: {
      margin: "30px 0 15px 0"
    }
  }
}));

export default function Home() {
  const classes = useStyles();
  const detailsRef = useRef(null);

  return (
    <div>
      {/* Cover */}
      <Box className={classes.cover}>
        <Box className={classes.coverLeftBox}>
          <Box className={classes.coverMobile}></Box>
        </Box>
        <Box className={classes.coverLeftBox}>
          <Typography variant={"h5"} className={classes.tagText}>
            Simple trip planning application
          </Typography>
          <Typography
            // variant={"h1"}
            className={classes.titileTextStyle}
          >
            TripPlanner
          </Typography>
          <Typography variant={"h5"} className={classes.supportText}>
            A beautifully designed, easy to use trips and travel planning application
          </Typography>
          <Box style={{ flexDirection: "row" }}>
            <GooglePlayButton packageName={ANDROID_PACKAGE_NAME_TRIP_PLANNER} />
            <AppStoreButton linkURL={APPSTORE_URL} />
          </Box>
        </Box>
      </Box>
      {/* Details */}
      <Box
        ref={detailsRef}
        className={classes.detailBox}
        display="flex"
        flexDirection="column"
        alignItems="center"
      >
        <Typography className={classes.headingTextStyle} align="center">
          Plan Your Trip
        </Typography>
        <Typography
          variant={"h5"}
          className={classes.supportText}
          align="center"
        >
          Plan and organize all your trips in a single place
        </Typography>
        <Box className={classes.imageContainer}>
          <Box
            className={classes.detailImage}
            style={{
              backgroundImage: `url("/images/add-loc.webp")`
            }}
          />
          <Box
            className={classes.detailImage}
            style={{
              backgroundImage: `url("/images/manage-plan.webp")`
            }}
          />
        </Box>
      </Box>
      {/* Auto Routing */}
      <Box
        className={classes.detailBox}
        display="flex"
        flexDirection="column"
        alignItems="center"
        style={{ background: gradient }}
      >
        <Typography className={classes.headingTextStyle} align="center">
          Auto Routing
        </Typography>
        <Typography
          variant={"h5"}
          className={classes.supportText}
          align="center"
        >
          We will help you plan the route automatically
        </Typography>
        <Box className={classes.imageContainer}>
          <Box
            className={classes.detailImage}
            style={{
              backgroundImage: `url("/images/auto-route.webp")`
            }}
          />
        </Box>
      </Box>
      {/* Share Plan */}
      <Box
        className={classes.detailBox}
        display="flex"
        flexDirection="column"
        alignItems="center"
      >
        <Typography className={classes.headingTextStyle} align="center">
          Share your plan
        </Typography>
        <Typography
          align="center"
          variant={"h5"}
          className={classes.supportText}
        >
          Share your plan to anyone. Or import the plan shared by someone!
        </Typography>
        <Box
          className={classes.detailImage}
          style={{
            backgroundImage: `url("/images/share-plan.webp")`
          }}
        />
      </Box>

      {/* Download */}
      <Box
        className={classes.detailBox}
        display="flex"
        flexDirection="column"
        alignItems="center"
        style={{ background: gradient }}
      >
        <Typography className={classes.headingTextStyle} align="center">
          Download now!
        </Typography>
        <Typography variant={"h5"} className={classes.supportText}>
          Let's go explore!
        </Typography>
        <Box style={{ flexDirection: "row" }}>
          <GooglePlayButton packageName={ANDROID_PACKAGE_NAME_TRIP_PLANNER} />
          <AppStoreButton />
        </Box>
      </Box>
      {/* More Apps */}
      <Box
        className={classes.detailBox}
        display="flex"
        flexDirection="column"
        alignItems="center"
      >
        <Typography align="center" className={classes.headingTextStyle}>
          More apps
        </Typography>
        <Typography variant={"h5"} className={classes.supportText}>
          Checkout our other travel apps
        </Typography>
        <Box display="flex" className={classes.appsBoxContainer}>
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            className={classes.appsBoxTile}
          >
            <img
              alt="trip-planner"
              src={"https://live-assets-cdn.travalour.com/travalour-web/Assets/NearByLogo_110x100.png"}
              style={{
                width: "75px",
                height: "75px",
                objectFit: "contain",
                borderRadius: 10
              }}
            />
            <Typography
              align="center"
              variant={"h5"}
              style={{ margin: "20px 0" }}
            >
              NearBy
            </Typography>
            <Typography
              align="center"
              className={classes.appDescr}
              variant={"body1"}
            >
              NearBy makes it easy and fast to find attractions near your current location. You can also discover destinations around the planet and create favourite list of attractions that you want to visit later.
            </Typography>
            <GooglePlayButton packageName={ANDROID_PACKAGE_NAME_NEARBY} />
          </Box>
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            className={classes.appsBoxTile}
          >
            <img
              alt="trip-planner"
              src={"/images/TRAVALOURLOGO.png"}
              style={{
                width: "75px",
                height: "75px",
                objectFit: "contain",
                borderRadius: 10
              }}
            />
            <Typography
              align="center"
              variant={"h5"}
              style={{ margin: "20px 0" }}
            >
              Travalour
            </Typography>
            <Typography
              align="center"
              className={classes.appDescr}
              variant={"body1"}
            >
              Everything in Nearby and more. With travalour, in addition to the
              functionalities you see in NearBy, you can store and organise your
              travel timeline. You will also be able to discover where
              travellers are going and also what is new in a destination you are
              interested in exploring.
            </Typography>
            <GooglePlayButton packageName={ANDROID_PACKAGE_NAME_TRAVALOUR} />
          </Box>
        </Box>
      </Box>
      {/* Media */}
      <Box
        className={classes.detailBox}
        display="flex"
        flexDirection="column"
        alignItems="center"
        style={{ background: gradient }}
      >
        <Typography className={classes.headingTextStyle} align="center">
          In Media
        </Typography>
        <Box display="flex" className={classes.mediaContainer}>
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            className={classes.mediaTile}
          >
            <img
              alt="startupanz"
              src={"/images/startupanz.png"}
              style={{
                width: "150px",
                height: "150px",
                objectFit: "contain",
                borderRadius: 10,
                marginBottom: 8
              }}
            />
            <Typography
              align="center"
              className={classes.appDescr}
              variant={"body1"}
            >
              How Kerala’s Startup Sector Is Growing Both Plants & Apps
            </Typography>
            <Link href="https://startupanz.com/keralas-start-scene-growing-plants-apps/" underline="none" target={"_blank"}>
              <Typography
                align="center"
                variant={"h6"}
              >
                Startupanz
              </Typography>
            </Link>
          </Box>
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            className={classes.mediaTile}
          >
            <img
              alt="kerala-insider"
              src={"/images/kerala-insider.png"}
              style={{
                width: "150px",
                height: "150px",
                objectFit: "contain",
                borderRadius: 10,
                marginBottom: 8
              }}
            />
            <Typography
              align="center"
              className={classes.appDescr}
              variant={"body1"}
            >
              TripPlanner and Travalour – Two apps to create, manage and share trips and travel plans.
            </Typography>
            <Link href="https://www.keralainsider.com/tripplanner-and-travalour/" underline="none" target={"_blank"}>
              <Typography
                align="center"
                variant={"h6"}
              >
                Kerala Insider
              </Typography>
            </Link>
          </Box>
        </Box>
      </Box>
      {/* <DownloadAppDialog open={open} handleClose={handleClose} /> */}
    </div>
  );
}
