import React from "react";

import Link from "@material-ui/core/Link";

export default function AppStoreButton({ onClick }) {
  return (
    <Link
      href={`https://apps.apple.com/sg/app/tripplanner-travel-planner/id1468048318?mt=8`}
      target="_blank"
      underline="none"
      onClick={onClick}
    >
      <img
        style={{ width: "135px", height: "40px", resizeMode: "center", margin: "2px" }}
        alt="Download on the App Store"
        src={`https://live-assets-cdn.travalour.com/buttons/download-on-the-app-store.svg`}

      />
    </Link>
  );
}
