import React, { Fragment } from "react";
import { Router, Switch } from "react-router-dom";
import history from "../store/history";
import DefaultRoute from "./DefaultRoute";
import HomePage from "../pages/Home";
import TermsPage from "../pages/Terms";
import PrivacyPolicyPage from "../pages/PrivacyPolicy";
import NotFoundPage from "../pages/NotFound";

export default function AppRouter() {
  return (
    <Fragment>
      <Router history={history}>
        <Switch>
          <DefaultRoute path="/" component={HomePage} exact />
          <DefaultRoute
            path="/privacy-policy"
            component={PrivacyPolicyPage}
          />
          <DefaultRoute path="/terms-of-use" component={TermsPage} />
          <DefaultRoute component={NotFoundPage} />
        </Switch>
      </Router>
    </Fragment>
  );
}
