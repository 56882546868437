import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";

import { MENU_DRAWER_WIDTH } from "../../config";
import Navbar from "./navbar";
import Menu from "./menu";
import Footer from "./footer";

const drawerWidth = MENU_DRAWER_WIDTH;

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: "#F5F7F9",
    //backgroundColor: "#ececec",
    position: "relative",
    minHeight: "100vh"
  },
  wrapper: {
    display: "flex",
    paddingBottom: "9em" //height of the footer
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0
  },
  drawerPaper: {
    width: drawerWidth,
    backgroundColor: "#ececec",
    color: "#095460"
  },
  drawerClose: {
    color: theme.palette.primary.main
  },
  content: {
    flexGrow: 1,
    paddingTop: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
  }
}));

export default function Home({ children }) {
  const classes = useStyles();
  const [drawerOpen, setDrawerOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };

  return (
    <div className={classes.root}>
      <div className={classes.wrapper}>
        <Navbar drawerOpen={drawerOpen} handleDrawerOpen={handleDrawerOpen} />
        <main className={classes.content}>{children}</main>
        <Drawer
          className={classes.drawer}
          anchor="right"
          open={drawerOpen}
          onClose={handleDrawerClose}
          classes={{
            paper: classes.drawerPaper
          }}
        >
          <div className={classes.drawerHeader}>
            <IconButton onClick={handleDrawerClose}>
              <ChevronRightIcon className={classes.drawerClose} />
            </IconButton>
          </div>
          <Divider />
          <Menu handleDrawerClose={handleDrawerClose} />
        </Drawer>
      </div>
      <Footer />
    </div>
  );
}
