import React from "react";
import { Link as RouterLink } from "react-router-dom";

import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Box from "@material-ui/core/Box";
import Toolbar from "@material-ui/core/Toolbar";
import Container from "@material-ui/core/Container";

import MenuIcon from "@material-ui/icons/Menu";
import IconButton from "@material-ui/core/IconButton";

const useStyles = makeStyles(theme => ({
  appBar: {
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },
  toolbar: {
    minHeight: "50px",
    [theme.breakpoints.down("xs")]: {
      padding: 0
    }
  },
  toolbarContainer: {
    maxWidth: "1300px",
    height: "50px"
  },
  title: {
    color: "#fff",
    fontSize: 18,
    fontWeight: "bold",
    paddingRight: theme.spacing(1),
    paddingTop: theme.spacing(0.5),
    [theme.breakpoints.down("xs")]: {
      //display: "none"
    }
  },
  logo: {
    height: 35,
    width: 32,
    marginRight: 10
  },
  hide: {
    display: "none"
  }
}));

export default function Navbar({ drawerOpen, handleDrawerOpen }) {
  const classes = useStyles();

  return (
    <AppBar position="fixed" className={classes.appBar}>
      <Container className={classes.toolbarContainer}>
        <Toolbar className={classes.toolbar}>
          {/*<IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={() => history.push("/")}
            className={classes.logo}
            edge="start"
          >
            <i className="tw tw-travalour-t-logo"></i>
            <span className={classes.title}>{" Travalour"}</span>
          </IconButton>*/}
          <RouterLink to="/" style={{ textDecoration: "none" }}>
            <Box display="flex" alignItems="center">
              {/* <i className={`tw tw-travalour-t-logo ${classes.logo}`}></i> */}
              <img
                src={"/images/logo.png"}
                alt="Logo"
                className={classes.logo}
              />
              <span className={classes.title}>{" TripPlanner"}</span>
            </Box>
          </RouterLink>
          {/*<GlobalSearch />*/}
          <div style={{ flexGrow: 1 }} />
          <div>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              className={clsx(drawerOpen && classes.hide)}
              style={{
                padding: 0
              }}
            >
              <MenuIcon />
            </IconButton>
          </div>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
