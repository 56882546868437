export const MENU_DRAWER_WIDTH = 200;

export const TRAVALOUR_WEB_URL = "https://www.travalour.com";
export const TRAVALOUR_BOOKING_URL = "https://booking.travalour.com";
export const NEARBY_URL = "https://nearby.travalour.com/";
export const SOCIAL_FB_URL = "https://facebook.com/Travalour";
export const SOCIAL_TWITTER_URL = "https://twitter.com/travalour";
export const SOCIAL_INSTA_URL = "https://www.instagram.com/travalour";

export const ANDROID_PACKAGE_NAME_NEARBY = "com.travalour.travel.nearby";
export const ANDROID_PACKAGE_NAME_TRAVALOUR = "com.travalour.travel.app";
export const ANDROID_PACKAGE_NAME_TRIP_PLANNER = "com.travalour.tripplanner";
export const APPSTORE_URL =
  "https://apps.apple.com/sg/app/nearby-find-attractions-nearby/id1495617624";
