import React from "react";

import Box from "@material-ui/core/Box";
import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";

import Facebook from "@material-ui/icons/Facebook";
import Twitter from "@material-ui/icons/Twitter";
import Instagram from "@material-ui/icons/Instagram";

import {
  SOCIAL_FB_URL,
  SOCIAL_TWITTER_URL,
  SOCIAL_INSTA_URL
} from "../../config";

const useStyles = makeStyles(theme => ({
  footer: {
    width: "100%",
    height: "9em",
    padding: "15px",
    backgroundColor: theme.palette.primary.main,
    color: "#fff",
    position: "absolute",
    bottom: 0,
    display: "block"
  },
  socialIcon: { color: "white" },
  iconContainer: {
    display: "flex",
    alignItems: "center",
    marginBottom: 8,
    paddingLeft: 36,
  },
  linkText: {
    paddingTop: 4,
    paddingBottom: 4,
    paddingLeft: 4,
    fontSize: 12,
    color: "white",
  },
}));

export default function Footer() {
  const classes = useStyles();

  return (
    <footer>
      <Box className={classes.footer}>
        <Box
          justifyContent="center"
          display="flex"
          flexDirection="column"
          alignItems="center"
        >
          <Box>
            <Typography variant="subtitle1" style={{ color: "#fff" }}>
              Connect with us
            </Typography>
          </Box>
          <Box display="flex" justifyContent="center">
            <Link href={SOCIAL_TWITTER_URL} target="_blank">
              <Box className={classes.iconContainer}>
                <Twitter className={classes.socialIcon} />
                <Typography variant="body2" className={classes.linkText}>
                  Twitter
                </Typography>
              </Box>
            </Link>

            <Link href={SOCIAL_FB_URL} target="_blank">
              <Box className={classes.iconContainer}>
                <Facebook className={classes.socialIcon} />
                <Typography variant="body2" className={classes.linkText}>
                  Facebook
                </Typography>
              </Box>
            </Link>
            <Link href={SOCIAL_INSTA_URL} target="_blank">
              <Box className={classes.iconContainer}>
                <Instagram className={classes.socialIcon} />
                <Typography variant="body2" className={classes.linkText}>
                  Instagram
                </Typography>
              </Box>
            </Link>
          </Box>
        </Box>
        <Box justifyContent="center" display="flex">
          <Typography variant="caption" style={{ color: "#e7e7e7" }}>
            © {new Date().getFullYear()} Travalour. All Rights Reserved
          </Typography>
        </Box>
      </Box>
    </footer>
  );
}
