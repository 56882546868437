import React from "react";
import { Route } from "react-router-dom";

import Layout from "../components/layout";

export default function DefaultRoute({ component: Component, ...rest }) {
  return (
    <Route
      {...rest}
      component={props => (
        <Layout>
          <Component {...props} />
        </Layout>
      )}
    />
  );
}
