import React from "react";

import Link from "@material-ui/core/Link";

export default function GooglePlayButton({ onClick, packageName }) {
  return (
    <Link
      href={`http://play.google.com/store/apps/details?id=${packageName}`}
      target="blank"
      underline="none"
      onClick={onClick}
    >
      <img
        style={{ width: "135px", height: "40px", resizeMode: "center", margin: "2px" }}
        alt="Get it on Google Play"
        src="https://live-assets-cdn.travalour.com/buttons/get-it-on-google-play.png"
      />
    </Link>
  );
}
