import React from "react";

import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";

// import ReactHelmet from "../components/helmet/ReactHelmet";
import Content from "../components/layout/content";

const useStyles = makeStyles(theme => ({
  container: {
    padding: theme.spacing(2, 3)
  },
  headingStyle: {
    fontWeight: "bold",
    marginTop: "10px",
    marginBottom: "10px"
  }
}));

export default function Terms() {
  const classes = useStyles();

  return (
    <Content>
      {/* <ReactHelmet title={"Terms Of Use"} /> */}
      {/*   */}
      <Container className={classes.container}>
        <Typography variant="h6" className={classes.headingStyle}>
          USER ACCEPTANCE
        </Typography>
        <Typography variant="body1" color="textSecondary" paragraph>
          Welcome to tripplanner.travalour.com. This is an agreement between Travalour Pte Ltd, owner of tripplanner.travalour.com (“the site”) and its services, and you(you or your or user(s)), a user of the site, and its services.
        </Typography>
        <Typography variant="body1" color="textSecondary" paragraph>
          Throughout this document, the words TripPlanner, Travalour, us, we, and our, refers toTravalour.com, or our services. Your ability to submit or transmit any information through our site or service will be referred to as ‘content’ throughout this agreement.
        </Typography>
        <Typography variant="body1" color="textSecondary" paragraph>
          By agreeing to use our site, you agree to these terms of service and the privacy policy. We may amend our terms of service or privacy policy, the updates can be found on this page.
        </Typography>
        <Typography variant="body1" color="textSecondary" paragraph>
          If you do not agree to the terms of service or the privacy policy please cease using our site and service immediately.
        </Typography>
        <Typography variant="h6" paragraph className={classes.headingStyle}>
          SERVICE DESCRIPTION
        </Typography>
        <Typography variant="body1" color="textSecondary" paragraph>
          TripPlanner helps its users to plan their trips and travel plans fast and easy.
        </Typography>
        <Typography variant="h6" className={classes.headingStyle}>
          DISCLAIMER AND INFORMATION ACCURACY
        </Typography>
        <Typography variant="body1" color="textSecondary" paragraph>
          Our services are to be used for planning trips and travels, with maps being a large part of our user experience. Although we strive to provide the most accurate maps and information possible, we cannot guarantee that use of our service will result in exact desired results for you. We cannot guarantee that all locations are accurate on our maps. We primarily use Google Maps in our platform, although their data is generally accurate, errors may occur. Please do not rely on our service if you have any concern that your safety or the safety of others might be at risk.
        </Typography>
        <Typography variant="h6" className={classes.headingStyle}>
          OUR LICENSE GRANT TO YOU
        </Typography>
        <Typography variant="body1" color="textSecondary" paragraph>
          We grant you a non-commercial personal, non-exclusive, revocable, limited license to TripPlanner website and its services. You acknowledge that we maintain all rights, title, and interest in TripPlanner. This means you cannot reverse engineer or otherwise attempt to copy our website and app, or try and make money off of it without our express written permission.
        </Typography>
        <Typography variant="h6" className={classes.headingStyle}>
          USE OF TRIPPLANNER
        </Typography>
        <Typography variant="body1" color="textSecondary" paragraph>
          When using TripPlanner and our services, you are responsible for your use of TripPlanner. You also agree that your use of TripPlanner is for personal non-commercial use. You agree not to access, copy, or otherwise use TripPlanner, including our intellectual property and trademarks, except as authorised by these terms of service or as otherwise authorised in writing by Travalour. You agree not to use TripPlanner to:
        </Typography>
        <Typography variant="body1" color="textSecondary" paragraph>
          You will not use any automated system, including without limitation robots, spiders, offline readers, etc., to access the site or the service in a manner that sends more request messages to TripPlanner servers, or to services it use to deliver end user functionalities than a human can reasonably produce in the same period of time by using a conventional on-line web browser.
        </Typography>
        <Typography variant="body1" color="textSecondary" paragraph>
          You will not attempt to interfere with, compromise the system integrity or security, or decipher any transmissions to or from the servers running the Site, or Service
        </Typography>
        <Typography variant="body1" color="textSecondary" paragraph>
          You will not take any action that imposes, or may impose at our sole discretion, an unreasonable or disproportionately large load on our infrastructure
        </Typography>
        <Typography variant="body1" color="textSecondary" paragraph>
          You will not upload invalid data, viruses, worms or other software agents through the site or service
        </Typography>
        <Typography variant="body1" color="textSecondary" paragraph>
          You will not collect or harvest any personally identifiable
          information;
        </Typography>
        <Typography variant="body1" color="textSecondary" paragraph>
          You will not use the site, or the service for any commercial
          solicitation purposes;
        </Typography>
        <Typography variant="body1" color="textSecondary" paragraph>
          You agree to act within the bounds of common decency when using our
          site and services;
        </Typography>
        <Typography variant="body1" color="textSecondary" paragraph>
          You agree not to stalk, harass, bully or harm another individual;
        </Typography>
        <Typography variant="body1" color="textSecondary" paragraph>
          You agree not to impersonate any person or entity or misrepresent your
          affiliation with a person or entity;
        </Typography>
        <Typography variant="body1" color="textSecondary" paragraph>
          You agree that you will not hold Travalour or TripPlanner responsible for your use of our site;
        </Typography>
        <Typography variant="body1" color="textSecondary" paragraph>
          You agree not to interfere with or disrupt the site, or service;
        </Typography>
        <Typography variant="body1" color="textSecondary" paragraph>
          You agree not to hack, spam or phish us or other users;
        </Typography>
        <Typography variant="body1" color="textSecondary" paragraph>
          You agree to not violate any law or regulation and you are responsible
          for such violations;
        </Typography>
        <Typography variant="body1" color="textSecondary" paragraph>
          You agree not to cause, or aid in, the destruction, manipulation,
          removal, disabling, or impairment of any portion of our site,
          including the de-indexing or de-caching of any portion of our site
          from a thirty party’s website, such as by requesting its removal from
          a search engine;
        </Typography>
        <Typography variant="body1" color="textSecondary" paragraph>
          If you are discovered to be undertaking any of the aforementioned
          actions your privileges to use our service may at our discretion be
          terminated or suspended. Generally, we will provide an explanation for
          any suspension or termination of your use of any of our services, but
          Travalour reserves the right to suspend or terminate any account at
          any time without notice or explanation.
        </Typography>
        <Typography variant="h6" className={classes.headingStyle}>
          SITE {"&"} SERVICE AVAILABILITY
        </Typography>
        <Typography variant="body1" color="textSecondary" paragraph>
          We do not guarantee that our site or service will always be available,
          work, or be accessible at any particular time. Only users who are
          eligible to use our site may do so. We reserve the right to terminate
          access for anyone. We cannot guarantee that the site will work as
          advertised, or that it will give you the desired results.
        </Typography>
        <Typography variant="h6" className={classes.headingStyle}>
          COMPLIANCE WITH LAWS AND REGULATIONS
        </Typography>
        <Typography variant="body1" color="textSecondary" paragraph>
          Travalour is not responsible for your violation of any laws while
          using our site and service. Users must comply with all local, state,
          or federal laws regarding your use of our site and service.
        </Typography>
        <Typography variant="body1" color="textSecondary" paragraph>
          This policy, and the relationship between you and us under this terms,
          shall in all respects be interpreted in accordance with and governed
          by the laws of Singapore, without regard to its conflict of laws
          principles, and the parties agree to submit to the exclusive
          jurisdiction of the Singapore in relation to any dispute arising out
          of or in connection with this terms.
        </Typography>
        <Typography variant="h6" className={classes.headingStyle}>
          INTELLECTUAL PROPERTY
        </Typography>
        <Typography variant="body1" color="textSecondary" paragraph>
          The design of the tripPlanner.travalour.com along with created text, templates, scripts, graphics, interactive features and the trademarks, service marks and logos contained therein, are owned by or licensed toTravalour, subject to copyright and other intellectual property rights. Travalour reserves all rights not expressly granted in and to the service and the site. You agree to not engage in the use, copying, or distribution anything contained within the Site orService unless we have given express written permission.
        </Typography>
        <Typography variant="h6" className={classes.headingStyle}>
          WARRANTIES
        </Typography>
        <Typography variant="body1" color="textSecondary" paragraph>
          Our site or service are offered as-is, including any errors, bugs or
          other technical issues. You agree that while using our site or
          service, your results may vary and you may not achieve any desired
          results. Our site, or service may not be fit for a particular purpose
          and may not work or be applicable where you reside. We make no
          warranties as to the reasonable skill and care invested in our
          service, satisfactory quality of our service, merchantability of our
          service or that our service is non-infringing. Furthermore, we do not
          warrant that the functions contained in the site or service will meet
          any requirements or needs you may have, that the site or service will
          operate error free or in an uninterrupted fashion, that any defects or
          errors in the site or service will be corrected, or that the site or
          service is compatible with any particular platform. Travalour is not
          obligated to provide you access to our site and service. In the event
          of any problem with the site or service, you agree that your sole
          remedy is to cease using the site or service.
        </Typography>
        <Typography variant="h6" className={classes.headingStyle}>
          LIMITATION OF LIABILITY
        </Typography>
        <Typography variant="body1" color="textSecondary" paragraph>
          In no event shall Travalour Pte Ltd, its officers, directors,
          employees, or agents, be liable to you for any direct, indirect,
          incidental, special, punitive, or consequential damages whatsoever
          resulting from(i) your use or inability to use the website or our
          services due to errors, mistakes, or inaccuracies of content, (ii) any
          personal injury or property damage, of any nature whatsoever,
          resulting from your access to and use of our site or service, (iii)
          any bugs, viruses, trojan horses, or the like, which may be
          transmitted to or through our site by any third party, (iv) any
          failure of any third party information listed on our site and service,
          including any failures or disruptions whether intentional or
          unintentional or (v)any errors or omissions in our site or service for
          any loss or damage of any kind incurred as a result of your use of our
          site or service, whether based on warranty, contract, tort, or any
          other legal theory, and whether or not the company is advised of the
          possibility of such damages.
        </Typography>
        <Typography variant="h6" className={classes.headingStyle}>
          INDEMNITY
        </Typography>
        <Typography variant="body1" color="textSecondary" paragraph>
          You agree to defend, indemnify and hold harmless Travalour Pte Ltd,
          its officers, directors, employees and agents, from and against any
          and all claims, damages, obligations, losses, liabilities, costs or
          debt, and expenses (including but not limited to attorney’s fees)
          arising from:
        </Typography>
        <Typography variant="body1" color="textSecondary" paragraph>
          Your use of and access to the TripPlanner site and service;
        </Typography>
        <Typography variant="body1" color="textSecondary" paragraph>
          Your violation of any term of these Terms of Service;
        </Typography>
        <Typography variant="body1" color="textSecondary" paragraph>
          Your violation of any third party right, including without limitation
          any copyright, property, or privacy right;
        </Typography>
        <Typography variant="body1" color="textSecondary" paragraph>
          Any claim that any of your content caused damage to a third party.
        </Typography>
        <Typography variant="body1" color="textSecondary" paragraph>
          This defence and indemnification obligation will survive this
          agreement and your use of the TripPlanner service. You also agree that you
          have a duty to defend us against such claims and we may require you to
          pay for an attorney(s) of our choice in such cases. You agree that
          this indemnity extends to requiring you to pay for our reasonable
          attorneys’ fees, court costs, and disbursements. In the event of a
          claim such as one described in this paragraph, we may elect to settle
          with the party/parties making the claim and you shall be liable for
          the damages as though we had proceeded with a trial.
        </Typography>
        <Typography variant="h6" className={classes.headingStyle}>
          TERMINATION AND CANCELLATION
        </Typography>
        <Typography variant="body1" color="textSecondary" paragraph>
          We may terminate or suspend service, your access to TripPlanner, or the
          website without notice, though we will strive to provide a timely
          explanation in most cases. If you wish to terminate this agreement,
          you are solely responsible for following proper termination procedures
        </Typography>
        <Typography variant="h6" className={classes.headingStyle}>
          AMENDMENTS
        </Typography>
        <Typography variant="body1" color="textSecondary" paragraph>
          We may amend this Agreement from time to time. When we amend this
          Agreement, we will update this page. You may refuse to agree to the
          amendments, if you do, you must immediately cease using our website
          and our Services.
        </Typography>
        <Typography variant="body1" color="textSecondary" paragraph>
          LAST MODIFIED: 21/01/2020
        </Typography>
      </Container>
    </Content>
  );
}
